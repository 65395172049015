import React from 'react';
import celebrities from './celebrities';
import Slider from "react-slick";

class CelebritiesCarousel extends React.Component {
	constructor(props) {
		super(props);
		this.celebritiesDefault = [
			celebrities[5],
			celebrities[19],
			celebrities[7],
			celebrities[26],
			celebrities[2],
			celebrities[0]
		];
		this.isPhone = window.innerWidth < 768 ? true : false;
		this.state = {
			selectedCelebrity: null
		}
	}

	selectCelebrity = (idx) => {
		let selected = this.celebritiesDefault[idx];
		const gallery = document.getElementsByClassName('celebrity-img');
		if (selected === this.state.selectedCelebrity) {
			[...gallery].forEach(element => {
				element.classList.remove('celebrity-img-selected');
			});
			this.setState({selectedCelebrity: null});
			this.props.selectCelebrity(null);
		} else {
			[...gallery].forEach(element => {
				element.classList.remove('celebrity-img-selected');
			});
			const selectedImage = document.getElementById(`carousel-item-${idx}`);
			selectedImage.classList.add('celebrity-img-selected');
			this.setState({selectedCelebrity: selected});
			this.props.selectCelebrity(selected);
		}
	}

	render() {
		const settings = {
			infinite: false,
			speed: 500,
			centerMode: this.isPhone ? true : false,
			slidesToShow: this.isPhone ? 1 : 4,
			slidesToScroll: 1,
			swipeToSlide: true,
			touchMove: true
		};
		return (
			<React.Fragment>
				<Slider {...settings} className="celebrities-carousel mb-5">
					{this.celebritiesDefault && this.celebritiesDefault.map((celebrity, index) => {
						return (
							<div key={`carousel-item-${index}`} style={{background: 'transparent'}} onClick={() => this.selectCelebrity(index)}>
								<div className="celebrity-bg-wrapper px-4 position-relative">
									<div className={`celebrity-bg ${(celebrity === this.state.selectedCelebrity) && 'celebrity-bg-selected'}`}>
										<img id={`carousel-item-${index}`} src={`/celebrities/${celebrity.name}.png`} alt={`MEME ${index}`} className={`celebrity-img img-fluid d-block celebrity-img-${celebrity?.alternate ? celebrity.alternate : celebrity.color} ${this.state.selectedCelebrity && this.state.selectedCelebrity !== this.celebritiesDefault[index] && 'greyscale'}`} />
									</div>
								</div>
								<div className="px-4">
									<span className="leitura-b my-2 d-block">{celebrity.name} {(celebrity === this.state.selectedCelebrity) && <i className="fa fa-check" style={{color: '#FFCC6B'}}/>}</span>
								</div>
							</div>
						);
					})}
					<div onClick={() => this.props.showMore()}>
						{
							!this.props.error ? (<img src="/celebrities/Seemore.png" alt="See more" className="img-fluid px-4 d-block" />) :
							(<img src="/celebrities/Filltheform.png" alt="Fill the form" className="img-fluid px-4 d-block" />)
						}
						<span className="leitura-b my-2 d-block pl-1 d-none"></span>
					</div>
				</Slider>
			</React.Fragment>
		);
	}
}

export default CelebritiesCarousel;