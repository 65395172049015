import React, { Component } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { routeTypeFriends } from './index';
import SharedHomepage from './sharedHomepage.jsx';

export default class App extends Component {
    render() {
        return (
            <React.Fragment>
                <HelmetProvider><Helmet>
                    <title>Calculate your Friendship Compatibility for Free</title>
                    <meta content="Calculate your Friendship Compatibility for Free" property="og:title" />
                    <meta content="Calculate your Friendship Compatibility for Free" property="twitter:title" />
                    <meta content="With Moonzo, find out who are your most compatible friends and predict how smooth and successful your relationships will be." name="description" />
                    <meta content="With Moonzo, find out who are your most compatible friends and predict how smooth and successful your relationships will be." name="og:description" />
                    <meta content="With Moonzo, find out who are your most compatible friends and predict how smooth and successful your relationships will be." name="twitter:description" />
                    <meta property="og:image" content="https://www.moonzo.com/sharingLanding.png" />
                    <link href="https://www.moonzo.com/moonzo_favicon.jpeg" rel="shortcut icon" type="image/x-icon" />
                    <link href="https://www.moonzo.com/moonzo_favicon.jpeg" rel="apple-touch-icon" />
                </Helmet></HelmetProvider>
                <SharedHomepage type={routeTypeFriends} />
            </React.Fragment>
        );
    }
}
