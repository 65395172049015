import React, { Component } from "react";
import Footer from './Footer';
import LOGO from './images/LOGO.png';
import AstrologicalSymbol from './AstrologicalSymbol';
import Chart from 'react-apexcharts';
import domtoimage from 'dom-to-image';

export default class CompatibilityResultModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false
		};
		this.windowWidth = false;
		if(window.innerWidth < 576) {
			this.windowWidth = true;
		}
	}

    createOption = (data, color) => {
        return {
            series: [data || 0],
            chart: {
            height: 350,
            type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    hollow: {
						size: this.getResponsiveHollow()
					},
                    dataLabels: {
                        offsetY: 73,
                        name: {
                          show: true,
                          offsetY: 10,
                          color: '#000000',
                          fontSize: this.getResponsiveChartFontSize(),
                          fontFamily: 'leitura-b',
                          fontWeight: 600,
                          formatter: () => (data + '%')
                        },
                        value: {
                          show: false
                        }
                    }
                }
            },
            fill: {
                colors: [color]
            },
        };
    }

    closeModal = () => {
        this.props.onClose();
    }

    getResponsiveChartFontSize = () => {
        let responsiveChartFontSize = '40px';
        if (this.windowWidth) {
            responsiveChartFontSize = '25px';
        }
        return responsiveChartFontSize;
    }

    getResponsiveChartHeight = () => {
        let responsiveChartHeight = 230;
        if (this.windowWidth) {
            responsiveChartHeight = 140;
        }
        return responsiveChartHeight;
	}

	getResponsiveHollow = () => {
		let responsiveChartHeight = '70%';
        if (this.windowWidth) {
            responsiveChartHeight = '60%';
        }
        return responsiveChartHeight;
	}

    saveDoc = (el) => {
		el.stopPropagation();
		this.setState({loading: true});

		this.generateImage().then(() => {
			this.generateImage().then(() => {
				this.generateImage().then((img) => {
					const link = document.createElement('a');
					link.download = `Moonzo - ${this.props?.result?.data?.firstUserName} Compatibility`;
					link.href = img;
					link.click();
					this.setState({loading: false});
				})
			})
		})
	}

	generateImage () {
		const scale = 3;
		const resultPage = document.getElementById('page');
		const style = {
			transform: 'scale('+scale+')',
			transformOrigin: 'top left',
			width: resultPage.offsetWidth + "px",
			paddingTop: '3vh',
			height: (resultPage.offsetHeight) + "px"
		}
		const param = {
			height: resultPage.offsetHeight * scale,
			width: resultPage.offsetWidth * scale,
			quality: 1,
			style
	   }
		return new Promise(resolve => {
			domtoimage.toPng(resultPage, param)
				.then((page) => {
					return resolve(page);
				})
		});
	}

    render() {
        const data = this.props?.result?.data;
        const graph1 = this.createOption(data?.friendshipUserCompatibility, '#FFB97E');
        const graph2 = this.createOption(data?.workUserCompatibility, '#9ED6C8');
        const graph3 = this.createOption(data?.loveUserCompatibility, '#EED7EF');
		const graph4 = this.createOption(data?.sexUserCompatibility, '#FF696B');
        return (
            <React.Fragment>
				<div className="position-relative">
					<div className="container">
						<div className="row">
							<div className="col">
								<div className="ml-auto vertical-align position-absolute" style={{top: 8, right: 15}}>
									<i className="fa fa-times text-white cursor-pointer" style={{fontSize: '35px'}} onClick={this.closeModal}/>
								</div>
							</div>
						</div>
					</div>
					<div className="moonzo-modal-body green moonzo-result-page pt-4" id="page">
						<div className="container moonzo-result-container h-100">
							<div className="row result-resp-row-wrapper">
								<div className="col">
									<div className="row result-resp-row1-wrapper">
										<div className="col">
												<h1 className="text-center wicked result-title-resp">Zodiac compatibility</h1>
										</div>
									</div>
									<div className="row result-resp-row2-wrapper">
										<div className="col text-center col-vertical-mobile">
												<div className="moonzo-result-symbol text-center vertical-align">
													<div className="moonzo-result-symbol-sign mr-2 vertical-align">
														<AstrologicalSymbol className="img-fluid p-1" style={{textColor: 'white'}} symbol={data?.firstUserSolarSign} />
													</div>
													<span className="vertical-align">{data?.firstUserSolarSign} ({data?.firstUserName})</span>
												</div>
												<span className="moonzo-x-space vertical-align"><b>x</b></span>
												<div className="moonzo-result-symbol text-center">
													<div className="moonzo-result-symbol-sign mr-2 vertical-align">
														<AstrologicalSymbol className="img-fluid p-1" style={{textColor: 'white'}} symbol={data?.secondUserSolarSign} />
													</div>
													<span className="vertical-align">{data?.secondUserSolarSign} ({data?.secondUserName})</span>
												</div>
										</div>
									</div>
									<div className="row moonzo-result-graph-wrapper">
										<div className="col-6">
											<Chart options={graph1} series={graph1.series} type="radialBar" width="100%" height={this.getResponsiveChartHeight()} />
											<h4 className="font-600 text-center leitura result-subtitle-resp">Friendship</h4>
										</div>
										<div className="col-6">
											<Chart options={graph2} series={graph2.series} type="radialBar" width="100%" height={this.getResponsiveChartHeight()} />
											<h4 className="font-600 text-center leitura result-subtitle-resp">Work</h4>
										</div>
										<div className="col-6">
											<Chart options={graph3} series={graph3.series} type="radialBar" width="100%" height={this.getResponsiveChartHeight()} />
											<h4 className="font-600 text-center leitura result-subtitle-resp">Love</h4>
										</div>
										<div className="col-6">
											<Chart options={graph4} series={graph4.series} type="radialBar" width="100%" height={this.getResponsiveChartHeight()} />
											<h4 className="font-600 text-center leitura result-subtitle-resp">Sex</h4>
										</div>
									</div>
									<div className="row mt-4">
										<div className="col text-center">
											<a href="/">
												<img className="moonzo-logo-resp mx-a" width="150" src={LOGO} alt="Moonzo"/>
											</a>
										</div>
									</div>
								</div>
							</div>
                        </div>
                    </div>
					<button id="compatibility-result-share" type="button" className="btn moonzo-btn-primary shadow-sm moonzo-btn px-2 mt-1 vertical-align mx-auto position-absolute moonzo-share-insta"><a target="_blank" rel="noopener noreferrer" href={'https://moonzo.app.link/calculatecompatibility'}> DOWNLOAD THE APP </a></button>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}
