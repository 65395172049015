import React from 'react';
import { useHistory } from "react-router-dom";

const NotFound = () => {
	const history = useHistory();
	const handleClick = () => {
		history.push('/');
	}
	return (
		<div className="s1-bg-webp h-100">
			<div className="container h-100">
				<div className="row h-100">
					<div className="col vertical-align color-white text-center">
						<div>
							<h1 className="wicked d-block">Lost <br className="sm-show"/> in space <span role="img" aria-label="stop">&#x1F468;&#x200D;&#x1F680;&#128104;&#127997;&#8205;&#128640;</span></h1>
							<h4 className="d-block leitura">Error 404 - Page Not Found</h4>
							<span className="d-block leitura">The page you requested could not be found. We're working on it.</span>
							<button onClick={handleClick} type="button" className="btn shadow-sm moonzo-btn-primary d-block moonzo-btn px-5 mt-4 mx-auto">
								BACK TO HOMEPAGE
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default NotFound;