import React from 'react';
import LOGO from './images/LOGO.png';
import LOADER from './images/LOADER.gif';
import AutoCompleteText from './AutoCompleteText';
import firebase from 'firebase/app';
import celebrities from './celebrities';
import hourConvert from 'hour-convert';
import Footer from './Footer';
import CelebritiesCarousel from './CelebritiesCarousel';
import CelebritiesShowMore from './CelebritiesShowMore';
import 'firebase/functions';
import { routeTypeCelebrities, routeTypeFriends } from './index';
import TiktokIcon from './images/tiktok_white.svg';
import Instacon from './images/insta_white.svg';

firebase.initializeApp({
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL
});

const apiLocationId = process.env.REACT_APP_HERE_LOCATION_ID;
const apiLocationKey = process.env.REACT_APP_HERE_LOCATION_KEY;

class CompatibilityModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			name: window.localStorage.getItem('name') || '',
			day: window.localStorage.getItem('day') || '',
			month: window.localStorage.getItem('month') || '',
			year: window.localStorage.getItem('year') || '',
			birthplace: window.localStorage.getItem('birthplace') || '',
			hh: window.localStorage.getItem('hh') || '',
			mm: window.localStorage.getItem('mm') || '',
			time: window.localStorage.getItem('time') || 'AM',
			timezone: window.localStorage.getItem('timezone') || '',
			dst: window.localStorage.getItem('dst') || '',
			friendName: '',
			friendDay: '',
			friendMonth: '',
			friendYear: '',
			friendBirthplace: '',
			friendHh: '',
			friendMm: '',
			friendTime: 'AM',
			friendTimezone: '',
			friendDst: '',
			errors: null,
			hereapiError: null,
			celebrity: '',
			rememberMe: window.localStorage.getItem('rememberMe') || true,
			showMore: false,
			emailAddress: window.localStorage.getItem('emailAddress') || '',
			showMoreError: null
		};
	}

	closeModal = () => {
		this.props.onClose();
	}

	handleSubmit = (event) => {
		event && event.preventDefault();
		const form = document.getElementById('compatibility-form-valid');
		if (form && form.checkValidity() === false) {
			event && event.stopPropagation();
		}
		form && form.classList.add('was-validated');
		this.postCompatibility();
	}

	handleInputChange = (event) => {
		const target = event.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	}

	handleInputNumberChange = (event) => {
		const target = event.target;
		let value = target.value;
		const name = target.name;

		if ((target.type === 'number' && target.max && target.min) || (target.type === 'tel' && target.max && target.min)) {
			let v = parseInt(value),
				max = parseInt(target.max),
				min = parseInt(target.min);
			if (v > max) {
				value = value.substring(0, value.length - 1);
			}
			if (v < min) {
				value = '';
			}
		}

		this.setState({
			[name]: value
		});
	}

	getProperJson = (dataOrigin) => {
		let data = Object.assign(dataOrigin);

		// If celebrity
		if (this.state.celebrity) {
			let celebrityObject = celebrities.find(c => c.name === this.state.celebrity);
			data.friendName = celebrityObject.name;
			data.friendDay = celebrityObject.friendDay;
			data.friendMonth = celebrityObject.friendMonth;
			data.friendYear = celebrityObject.friendYear;
			data.friendBirthplace = '';
			data.friendHh = '';
			data.friendMm = '';
			data.friendTime = '';
			data.friendTimezone = '';
			data.friendDst = '';
		}

		// First user
		let firstUserDateExist = data?.year && data?.month && data?.day ? true : false;
		let firstUserEmailExist = data?.emailAddress ? true : false;
		let firstUserHour = (data?.hh && data?.time && hourConvert.to24Hour({ hour: data.hh, meridiem: data.time })) || null;
		let firstUserBirthday = data?.year + '-' + data?.month + '-' + data?.day;
		// let fullDate = firstUserBirthday + ' ' + firstUserHour + ':' + data?.mm + ' ' + this.state?.timezone?.tz;
		// let firstUserFullBirthday = new Date(fullDate);
		// let isDst = moment(firstUserFullBirthday).isDST();
		// let dst = isDst ? 3600 : -3600;

		// Second user
		let secondUserDateExist = data?.friendYear && data?.friendMonth && data?.friendDay ? true : false;
		let secondUserHour = (data?.friendHh && data?.friendTime && hourConvert.to24Hour({ hour: data.friendHh, meridiem: data.friendTime })) || null;
		let secondUserBirthday = data?.friendYear + '-' + data?.friendMonth + '-' + data?.friendDay;
		// let fullSecondDate = secondUserBirthday + ' ' + secondUserHour + ':' + data?.friendMm + ' ' + this.state?.friendTimezone?.tz;
		// let secondUserFullBirthday = new Date(fullSecondDate);
		// let isSecondDst = moment(secondUserFullBirthday).isDST();
		// let secondDst = isSecondDst ? 3600 : -3600;
		let properJson = {
			firstUserName: (data?.name) || null,
			firstUserBirthday: firstUserDateExist ? firstUserBirthday : null,
			firstUserHoursOfBirth: firstUserHour || null,
			firstUserMinutesOfBirth: (data?.mm) || null,
			firstUserCity: data && data.birthplace ? data.birthplace : '',
			firstUserEmail: data && data.emailAddress ? data.emailAddress : '',
			// firstUserTimezone: (data?.timezone?.tzs) || null,
			// firstUserDst: (firstUserDateExist && this.state?.timezone?.tz && dst) || null,
			secondUserName: (data?.friendName) || null,
			secondUserBirthDay: secondUserDateExist ? secondUserBirthday : null,
			secondUserHoursOfBirth: secondUserHour || null,
			secondUserMinutesOfBirth: (data?.friendMm) || null,
			secondUserCity: data && data.friendBirthplace ? data.friendBirthplace : '',
			// secondUserTimezone: (data?.friendTimezone?.tzs) || null,
			// secondUserDst: (secondUserDateExist && this.state?.friendTimezone?.tz && secondDst) || null,
		}

		let errors = {};
		if (!properJson.firstUserName) {
			errors.firstUserName = 'Please write your name.';
		}
		if (!firstUserDateExist) {
			errors.firstUserDateExist = 'Please enter a correct birthdate.';
		}
		if (!firstUserEmailExist) {
			errors.firstUserEmailExist = 'Please enter an email.';
		}
		if (!properJson.secondUserName) {
			if (this.props.type === routeTypeCelebrities) {
				errors.secondUserName = 'Please pick a celebrity.';
			} else {
				errors.secondUserName = 'Please write your friend name.';
			}
		}
		if (!secondUserDateExist) {
			errors.secondUserDateExist = 'Please enter a correct friend brithdate.';
		}

		this.setState({ errors: errors });
		if (!this.state.rememberMe) {
			window.localStorage.removeItem('name');
			window.localStorage.removeItem('day');
			window.localStorage.removeItem('month');
			window.localStorage.removeItem('year');
			window.localStorage.removeItem('birthplace');
			window.localStorage.removeItem('hh');
			window.localStorage.removeItem('mm');
			window.localStorage.removeItem('time');
			window.localStorage.removeItem('timezone');
			window.localStorage.removeItem('dst');
			window.localStorage.removeItem('rememberMe');
			window.localStorage.removeItem('emailAddress');
		}

		if (Object.keys(errors).length === 0 && window.localStorage.getItem('cookie') && window.localStorage.getItem('rememberMe')) {
			window.localStorage.setItem('name', this.state.name);
			window.localStorage.setItem('day', this.state.day);
			window.localStorage.setItem('month', this.state.month);
			window.localStorage.setItem('year', this.state.year);
			window.localStorage.setItem('birthplace', this.state.birthplace);
			window.localStorage.setItem('hh', this.state.hh);
			window.localStorage.setItem('mm', this.state.mm);
			window.localStorage.setItem('time', this.state.time);
			window.localStorage.setItem('timezone', this.state.timezone);
			window.localStorage.setItem('dst', this.state.dst);
			window.localStorage.setItem('emailAddress', this.state.emailAddress);
		}

		if (Object.keys(errors).length > 0) {
			return null;
		}
		return properJson;
	}

	postCompatibility = async () => {
		this.state.emailAddress && this.saveLabsEmail();
		if (this.state.rememberMe) {
			window.localStorage.setItem('rememberMe', true);
		} else {
			window.localStorage.setItem('rememberMe', false);
		}
		const data = this.getProperJson(this.state);
		data && this.props.onPostCompatibility(data);
	}

	handleAutoCompleteError = (err) => {
		if (err) {
			this.setState({ hereapiError: err });
		}
	}

	handleOnSuggestionSelected = (selection) => {
		this.setState({ timezone: selection });
	}

	handleOnFriendSuggestionSelected = (selection) => {
		this.setState({ friendTimezone: selection });
	}

	selectCelebrity = (celeb) => {
		this.setState({ celebrity: celeb?.name || null });
	}

	selectCelebrityOnShowMore = (celeb) => {
		this.setState({ celebrity: celeb.name }, this.handleSubmit);
	}

	showMore = () => {
		const { name, day, month, year } = this.state;
		if (name && day && month && year) {
			this.setState({ showMore: true });
			const compatibilityModalDom = document.getElementById('moonzo-modal-compatibility');
			compatibilityModalDom.style.backgroundSize = 'contain';
		} else {
			let smError = 'Please fill the form before picking another Celebrity.'
			this.setState({ showMoreError: smError });
		}
	}

	closeShowMore = () => {
		this.setState({ showMore: false });
		const compatibilityModalDom = document.getElementById('moonzo-modal-compatibility');
		compatibilityModalDom.style.backgroundSize = 'cover';
		this.setState({ showMoreError: null });
	}

	saveLabsEmail = () => {
		// This causes a bug
		// const { isAstroForBae } = this.props;
		// const id = isAstroForBae ? 'kol_bounce_form_button_323971' : 'kol_bounce_form_button_321597';
		// const emailSubmitBtn = document.getElementById(id);
		// emailSubmitBtn.click();
	}

	render() {
		let thisYear = new Date().getFullYear();
		const { isAstroForBae } = this.props;
		return (
			<React.Fragment>
				<div className={`moonzo-modal-body moonzo-modal-compatibility pb-5 ${this.state.showMore && 'moonzo-bg-showmore'}`} id="moonzo-modal-compatibility">
					<div className="container h-100">
						<div className="row">
							{!this.props.isLandingPage && <div className="col">
								<div className="d-flex pt-3">
									{this.state.showMore && (<div className="mr-auto vertical-align">
										<i className="fa fa-chevron-left text-white cursor-pointer" style={{ fontSize: '30px' }} onClick={this.closeShowMore} />
									</div>)}
									{(this.props.type === routeTypeFriends || this.props.type === routeTypeCelebrities) && <div className="d-flex w-100">
										<div className="vertical-align">
											<a href="/">
												<img className="moonzo-logo-resp" width="150" src={LOGO} alt="Moonzo" />
											</a>
										</div>
										<div className="ml-auto vertical-align">
											<a href="https://vm.tiktok.com/ZMejEY78h/" target="_blank" rel="noopener noreferrer">
												<img width="50" src={Instacon} alt="Instagram icon" />
											</a>
											<a href="https://www.instagram.com/moonzo.app/" target="_blank" rel="noopener noreferrer" className='fs-s3'>
												<img width="50" src={TiktokIcon} alt="Tiktok icon" />
											</a>
										</div>
									</div>}
								</div>
							</div>}
						</div>
						{this.state.showMore && <CelebritiesShowMore loading={this.props.loading} selectCelebrity={this.selectCelebrityOnShowMore} closeShowMore={this.closeShowMore} />}
						{!this.state.showMore &&
							(
								<React.Fragment>
									<div className="row pt-5">
										<div className="col">
											<h1 className="text-center wicked">Calculate your compatibility</h1>
											{this.props.type === routeTypeCelebrities && <h1 className="text-center leitura">with celebrities</h1>}
											{this.props.type === routeTypeFriends && <>
												<h1 className="text-center wicked">with your friends</h1>
												<h3 className="text-center mb-5-responsive mt-5-responsive" style={{ maxWidth: 750, marginLeft: 'auto', marginRight: 'auto' }}>With just your dates of birth, this compatibility application enables you to find out how smoothly your relationship is likely to develop.</h3>
											</>
											}
											<p className='text-center' style={{marginTop: '30px'}}>Sign up below to join our mailing list and receive your compatibility results.</p>
										</div>
									</div>
									<form onSubmit={this.handleSubmit} autoComplete="off" id="compatibility-form-valid" noValidate>
										<div className="row mt-5-responsive">
											<div className="col-lg-6 col-md-6 moonzo-form-padding">
												<div className="form-group mb-5-responsive">
													<label className="required moonzo-form-label" htmlFor="inputName">Your first name</label>
													<input required name="name" value={this.state.name} onChange={this.handleInputChange} type="text" className="form-control moonzo-input" id="inputName" aria-describedby="nameHelp" placeholder="Enter your first name" />
													<div className="invalid-feedback">
														{this.state.errors?.firstUserName}
													</div>
												</div>
												<div className="form-group mb-5-responsive">
													<label className="required moonzo-form-label">Your birthdate</label>
													<div className="form-row">
														<div className="col-6">
															<select required name="month" className="form-control moonzo-input" value={this.state.month} onChange={this.handleInputChange}>
																<option disabled value="">Month</option>
																<option value="01">January</option>
																<option value="02">February</option>
																<option value="03">March</option>
																<option value="04">April</option>
																<option value="05">May</option>
																<option value="06">June</option>
																<option value="07">July</option>
																<option value="08">August</option>
																<option value="09">September</option>
																<option value="10">October</option>
																<option value="11">November</option>
																<option value="12">December</option>
															</select>
														</div>
														<div className="col-3">
															<input name="day" min="1" max="31" value={this.state.day} onChange={this.handleInputChange} type="tel" className="form-control moonzo-input" id="inputDay" aria-describedby="dayHelp" placeholder="DD" required />
														</div>
														<div className="col-3">
															<input name="year" min="1920" max={thisYear} value={this.state.year} onChange={this.handleInputChange} type="tel" className="form-control moonzo-input" id="inputYear" aria-describedby="yearHelp" placeholder="YYYY" required />
														</div>
														<div className={`invalid-feedback ${this.state.errors?.firstUserDateExist && 'd-block'}`}>
															{this.state.errors?.firstUserDateExist}
														</div>
													</div>
												</div>
												<div className="form-group mb-5-responsive">
													<label className="moonzo-form-label required" htmlFor="emailAddress">Your email address</label>
													<input name="emailAddress" value={this.state.emailAddress} onChange={this.handleInputChange} type="text" className="form-control moonzo-input" id="emailAddress" required aria-describedby="emailAddressHelp" placeholder="Enter your email address" />
													<div className="invalid-feedback">
														{this.state.errors?.firstUserEmailExist}
													</div>
												</div>
											</div>
											<div className="col-lg-6 col-md-6 moonzo-form-padding">
												<div className="form-group mb-5-responsive">
													<label className="moonzo-form-label" htmlFor="inputBirthplace">Your birthplace (For Rising &#38; Moon signs)</label>
													<AutoCompleteText handleAutoCompleteError={this.handleAutoCompleteError} placeholder="Enter your birthplace" appid={apiLocationId} appcode={apiLocationKey} onSuggestionSelected={this.handleOnSuggestionSelected} />
												</div>
												<div className="form-group mb-5-responsive">
													<label className="moonzo-form-label">Your time for birth (For Rising &#38; Moon signs)</label>
													<div className="form-row">
														<div className="col">
															<input name="hh" min="1" max="12" value={this.state.hh} onChange={this.handleInputNumberChange} type="tel" className="form-control moonzo-input" id="inputHh" aria-describedby="hhHelp" placeholder="hh" />
														</div>
														<div className="col">
															<input name="mm" min="0" max="59" value={this.state.mm} onChange={this.handleInputNumberChange} type="tel" className="form-control moonzo-input" id="inputMm" aria-describedby="mmHelp" placeholder="mm" />
														</div>
														<div className="col">
															<select name="time" className="form-control moonzo-input" value={this.state.time} onChange={this.handleInputChange}>
																<option value="AM">AM</option>
																<option value="PM">PM</option>
															</select>
														</div>
													</div>
												</div>
												<div className="form-group custom-control custom-switch mb-5-responsive">
													<input type="checkbox" name="rememberMe" className="custom-control-input moonzo-custom-check" id="rememberMe" defaultChecked={this.state.rememberMe} onChange={this.handleInputChange} />
													<label className="custom-control-label" htmlFor="rememberMe" style={{ lineHeight: '23px' }}>Remember me</label>
												</div>
											</div>
											<hr className="moonzo-hr sm-show" />
											{/* YOUR FRIEND */}
											<div className="col">
												{this.props.type === routeTypeFriends &&
													(<React.Fragment>
														<div className="row">
															<div className="col-12 my-5-responsive">
																<h1 className="text-center wicked">Add a friend</h1>
															</div>
															<div className="col-lg-6 col-md-6 moonzo-form-padding">
																<div className="form-group  mb-5-responsive">
																	<label className="moonzo-form-label required" htmlFor="inputFriendName">First name</label>
																	<input name="friendName" value={this.state.friendName} onChange={this.handleInputChange} type="text" className="form-control moonzo-input" id="inputFriendName" aria-describedby="friendNameHelp" placeholder="Enter your friend’s first name" required />
																	<div className="invalid-feedback">
																		{this.state.errors?.secondUserName}
																	</div>
																</div>
																<div className="form-group  mb-5-responsive">
																	<label className="moonzo-form-label required">Your friend’s birthdate</label>
																	<div className="form-row">
																		<div className="col-6">
																			<select name="friendMonth" className="form-control moonzo-input" value={this.state.friendMonth} onChange={this.handleInputChange} required>
																				<option disabled value="">Month</option>
																				<option value="01">January</option>
																				<option value="02">February</option>
																				<option value="03">March</option>
																				<option value="04">April</option>
																				<option value="05">May</option>
																				<option value="06">June</option>
																				<option value="07">July</option>
																				<option value="08">August</option>
																				<option value="09">September</option>
																				<option value="10">October</option>
																				<option value="11">November</option>
																				<option value="12">December</option>
																			</select>
																		</div>
																		<div className="col-3">
																			<input name="friendDay" min="1" max="31" value={this.state.friendDay} onChange={this.handleInputChange} type="tel" className="form-control moonzo-input" id="inputFriendDay" aria-describedby="friendDayHelp" placeholder="DD" required />
																		</div>
																		<div className="col-3">
																			<input name="friendYear" min="1920" max={thisYear} value={this.state.friendYear} onChange={this.handleInputChange} type="tel" className="form-control moonzo-input" id="inputFriendYear" aria-describedby="friendYearHelp" placeholder="YYYY" required />
																		</div>
																		<div className={`invalid-feedback ${this.state.errors?.secondUserDateExist && 'd-block'}`}>
																			{this.state.errors?.secondUserDateExist}
																		</div>
																	</div>
																</div>
															</div>
															<div className="col-lg-6 col-md-6 moonzo-form-padding">
																<div className="form-group  mb-5-responsive">
																	<label className="moonzo-form-label" htmlFor="inputBirthplace">Birthplace (For Rising &#38; Moon signs)</label>
																	<AutoCompleteText handleAutoCompleteError={this.handleAutoCompleteError} placeholder="Enter your friend’s birthplace" appid={apiLocationId} appcode={apiLocationKey} onSuggestionSelected={this.handleOnFriendSuggestionSelected} />
																</div>
																<div className="form-group  mb-5-responsive">
																	<label className="moonzo-form-label">Time for birth (For Rising &#38; Moon signs)</label>
																	<div className="form-row">
																		<div className="col">
																			<input name="friendHh" min="1" max="12" value={this.state.friendHh} onChange={this.handleInputNumberChange} type="tel" className="form-control moonzo-input" id="inputFriendHh" aria-describedby="friendHhHelp" placeholder="hh" />
																		</div>
																		<div className="col">
																			<input name="friendMm" min="0" max="59" value={this.state.friendMm} onChange={this.handleInputNumberChange} type="tel" className="form-control moonzo-input" id="inputFriendMm" aria-describedby="friendMmHelp" placeholder="mm" />
																		</div>
																		<div className="col">
																			<select name="friendTime" className="form-control moonzo-input" value={this.state.friendTime} onChange={this.handleInputChange}>
																				<option value="AM">AM</option>
																				<option value="PM">PM</option>
																			</select>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</React.Fragment>)}
												{this.props.type === routeTypeCelebrities &&
													(
														<React.Fragment>
															<div className="row py-5-responsive">
																<div className="col">
																	<h1 className="text-center wicked">Pick a celebrity</h1>
																</div>
															</div>
															<CelebritiesCarousel showMore={this.showMore} error={this.state.showMoreError} selectCelebrity={this.selectCelebrity} id="celebrities-carousel" />
															<div className={`invalid-feedback ${this.state.errors?.secondUserName && 'd-block'}`}>
																{this.state.errors?.secondUserName}
															</div>
														</React.Fragment>
													)
												}
												<div className="form-group position-relative moonzo-btn-compatibility my-5-responsive-btn">
													<button type="button" id="compatibility-modal-submit" onClick={this.handleSubmit} className={`btn moonzo-btn-primary shadow-sm moonzo-btn px-5 vertical-align mx-auto ${this.props.type === routeTypeCelebrities ? 'gtag-submit-celebrity' : 'gtag-submit-friends'}`}>
														{(this.props.loading && <img src={LOADER} height="30" alt="loader" />) || 'Let’s see!'}
													</button>
												</div>
											</div>
										</div>
										{this.state.hereapiError &&
											<div className="row mt-3">
												<div className="col text-center">
													<span className="d-block my-1 color-red">There is an error with the location service, please try again later</span>
												</div>
											</div>
										}
										{this.props?.firebaseApiError &&
											<div className="row mt-3">
												<div className="col text-center">
													<span className="d-block my-1 color-red">There is an error with our service, please try again later</span>
												</div>
											</div>
										}
									</form>
									<form id={isAstroForBae ? 'kol_bounce_form_323971' : 'kol_bounce_form_321597'} name="kol_bounce_form" noValidate style={{ display: 'none' }}>
										<input readOnly aria-label="Email" type="email" className="kol_element kol_email required" id={isAstroForBae ? 'kol.323971.email' : 'kol.321597.email'} name="email" value={this.state.emailAddress} placeholder="Type your email here" />
										<input id={isAstroForBae ? 'kol_bounce_form_button_323971' : 'kol_bounce_form_button_321597'} type="submit" value="Subscribe" />
									</form>
								</React.Fragment>)}
					</div>
				</div>
				{this.props.isStandalonePage && <Footer />}
			</React.Fragment>
		);
	}
}

export default CompatibilityModal;
