import React, { Component } from "react";
import LOGO from './images/LOGO.png';
import LOADER from './images/LOADER.gif';
import CompatibilityModal from './CompatibilityModal';
import CompatibilityResultModal from './CompatibilityResultModal';
import EarlyModal from './EarlyModal';
import CompatibilityWrapper from './CompatibilityWrapper';
import Footer from './Footer';
import firebase from 'firebase/app';
import Modal from 'react-modal';
import LazyLoad from 'react-lazyload';
import { routeTypeDate } from './index';

const customStyles = {
	content: {
		width: '100%',
		height: '100%',
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		transform: 'translate(-50%, -50%)',
		padding: 0
	}
};

Modal.setAppElement('#root');

export default class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openModalType: '',
			modalIsOpen: false,
			modalEmailIsOpen: false,
			modalResultIsOpen: false,
			resultModal: null,
			modalResultLoading: false,
			firebaseApiError: null,
			isLoading: true,
			cookie: window.localStorage.getItem('cookie')
		};
		this.myRef = React.createRef(null);
	}

	componentDidMount() {
		this.setState({ isLoading: false });
	}

	setIsOpen = (stateValue) => {
		this.setState({ modalIsOpen: stateValue });
	}

	setEmailIsOpen = (stateValue) => {
		this.setState({ modalEmailIsOpen: stateValue });
	}

	setResultIsOpen = (stateValue) => {
		this.setState({ modalResultIsOpen: stateValue });
	}

	setResultLoading = (stateValue) => {
		this.setState({ modalResultLoading: stateValue });
	}

	setResultModal = (stateValue) => {
		this.setState({ resultModal: stateValue });
	}

	setFirebaseApiError = (stateValue) => {
		this.setState({ firebaseApiError: stateValue });
	}

	openModal = (type) => {
		this.setState({ openModalType: type });
		this.setResultLoading(false);
		this.setIsOpen(true);
	}

	closeModal = () => {
		this.setIsOpen(false);
	}

	openEmailModal = () => {
		this.setEmailIsOpen(true);
	}

	closeEmailModal = () => {
		this.setEmailIsOpen(false);
	}

	openResultModal = () => {
		this.setResultIsOpen(true);
	}

	closeResultModal = () => {
		this.setResultIsOpen(false);
	}

	handleOnPostCompatibility = async (result) => {
		let res = await result;
		this.setResultLoading(true);
		this.setFirebaseApiError(null);

		const computeUserCompatibility = firebase.functions().httpsCallable('computeUserCompatibility');
		computeUserCompatibility(res).then((percent) => {
			let hasError = typeof (percent?.data) === 'string';
			if (!hasError && percent) {
				this.setResultModal(percent);
				this.closeModal();
				this.openResultModal();
			}
			this.setResultLoading(false);
		}).catch((error) => {
			this.setFirebaseApiError(error);
			this.setResultLoading(false);
		});
	}

	removeCookieBar = () => {
		window.localStorage.setItem('cookie', true);
		this.setState({ cookie: true });
	}

	render() {
		const { modalEmailIsOpen, modalResultIsOpen, resultModal, firebaseApiError, isLoading, cookie } = this.state;
		const { type, isLandingPage } = this.props;
		const isAstroForBae = type === routeTypeDate;
		return (isLoading &&
			<div className="w-100 h-100 vertical-align" style={{ background: "#E9F0FE" }}>
				<div className="text-center">
					<LazyLoad offset={300} once><img className="mb-3" src={LOGO} width="150" alt="logo" /></LazyLoad>
					<br />
					<LazyLoad offset={300} once><img className="mx-auto" src={LOADER} height="40" alt="loader" /></LazyLoad>
				</div>
			</div>)
			||
			(<React.Fragment>
				<Modal
					isOpen={modalEmailIsOpen}
					onRequestClose={this.closeEmailModal}
					style={customStyles}
					contentLabel="Email Modal"
				>
					<EarlyModal onClose={this.closeEmailModal} />
				</Modal>
				<Modal
					isOpen={modalResultIsOpen}
					onRequestClose={this.closeResultModal}
					style={customStyles}
					contentLabel="Result Modal"
				>
					<CompatibilityResultModal onClose={this.closeResultModal} result={resultModal} />
				</Modal>
				<div className={`cookie-bar position-fixed w-100 avenir-s ${cookie && 'cookie-bar-hide'}`} id="cookie-bar">
					<div className="vertical-align">
						<span>
							To optimize your experience, this site uses cookies <span role="img" aria-label="cookie">&#127850;</span>, which you accept by continuing your navigation. <a href="https://www.notion.so/Privacy-b13301a47a8146ef88f07efa6775c6c5" rel="noopener noreferrer" target="_blank" style={{ textDecoration: 'underline' }}>Learn more</a>
						</span>
						<button onClick={this.removeCookieBar} type="button" className="btn moonzo-btn-secondary moonzo-btn text-center ml-3 cookie-bar-btn">Ok</button>
					</div>
				</div>
				<div className={!isLandingPage && !isAstroForBae ? 'formFirstPage' : ''}>
					<CompatibilityWrapper
						isAstroForBae={isAstroForBae}
						routeTypeDate={isAstroForBae}
						onOpen={this.openModal}
						type={this.props.type}
						isLandingPage={isLandingPage}
					/>
					<CompatibilityModal
						isLandingPage={isLandingPage}
						type={this.props.type}
						firebaseApiError={firebaseApiError}
						onPostCompatibility={this.handleOnPostCompatibility}
						isAstroForBae={isAstroForBae}
					/>
				</div>
				<LazyLoad offset={300} once>
					<Footer />
				</LazyLoad>
			</React.Fragment>
			);
	}
}
