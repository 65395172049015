const celebrities = [
	{ name: "A$AP Rocky", friendYear: 1988, friendMonth: 10, friendDay: 3, color: 'pink', alternate: 'green' },
	{ name: "Addison Rae", friendYear: 2000, friendMonth: 10, friendDay: 6, color: 'blue' },
	{ name: "Ariana Grande", friendYear: 1993, friendMonth: 6, friendDay: 26, color: 'green', alternate: 'orange' },
	{ name: "Barack Obama", friendYear: 1961, friendMonth: 8, friendDay: 4, color: 'orange' },
	{ name: "Beyoncé", friendYear: 1981, friendMonth: 9, friendDay: 4, color: 'pink' },
	{ name: "Billie Eilish", friendYear: 2001, friendMonth: 12, friendDay: 18, color: 'blue', alternate: 'orange' },
	{ name: "Brad Pitt", friendYear: 1963, friendMonth: 12, friendDay: 18, color: 'green' },
	{ name: "Charli d'Amelio", friendYear: 2004, friendMonth: 5, friendDay: 1, color: 'orange', alternate: 'blue' },
	{ name: "Chloë Grace Moretz", friendYear: 1997, friendMonth: 2, friendDay: 10, color: 'pink' },
	{ name: "Chris Brown", friendYear: 1989, friendMonth: 5, friendDay: 5, color: 'blue' },
	{ name: "Cole Sprouse", friendYear: 1992, friendMonth: 8, friendDay: 4, color: 'green' },
	{ name: "Dakota Johnson", friendYear: 1989, friendMonth: 10, friendDay: 4, color: 'orange' },
	{ name: "David Dobrik", friendYear: 1996, friendMonth: 7, friendDay: 23, color: 'pink' },
	{ name: "Dixie D'Amelio", friendYear: 2001, friendMonth: 8, friendDay: 12, color: 'blue' },
	{ name: "Drake", friendYear: 1986, friendMonth: 10, friendDay: 24, color: 'green' },
	{ name: "Emma Chamberlain", friendYear: 2001, friendMonth: 5, friendDay: 22, color: 'orange' },
	{ name: "Emma Watson", friendYear: 1990, friendMonth: 4, friendDay: 15, color: 'pink' },
	{ name: "George Washington", friendYear: 1732, friendMonth: 2, friendDay: 22, color: 'blue' },
	{ name: "Gigi Hadid", friendYear: 1995, friendMonth: 4, friendDay: 23, color: 'green' },
	{ name: "Harry Styles", friendYear: 1994, friendMonth: 2, friendDay: 1, color: 'orange', alternate: 'green' },
	{ name: "Ian Somerhalder", friendYear: 1978, friendMonth: 12, friendDay: 8, color: 'pink' },
	{ name: "James Charles", friendYear: 1999, friendMonth: 5, friendDay: 23, color: 'blue' },
	{ name: "Jay-Z", friendYear: 1969, friendMonth: 12, friendDay: 4, color: 'green' },
	{ name: "Jeffree Star", friendYear: 1985, friendMonth: 11, friendDay: 15, color: 'orange' },
	{ name: "Jennifer Aniston", friendYear: 1969, friendMonth: 2, friendDay: 11, color: 'pink' },
	{ name: "Jennifer Lopez", friendYear: 1969, friendMonth: 7, friendDay: 24, color: 'blue' },
	{ name: "Jungkook", friendYear: 1997, friendMonth: 9, friendDay: 1, color: 'green', alternate: 'pink' },
	{ name: "Justin Bieber", friendYear: 1994, friendMonth: 3, friendDay: 1, color: 'orange' },
	{ name: "Katy Perry", friendYear: 1984, friendMonth: 10, friendDay: 25, color: 'pink' },
	{ name: "Kendall Jenner", friendYear: 1995, friendMonth: 11, friendDay: 3, color: 'blue' },
	{ name: "Kevin Hart", friendYear: 1979, friendMonth: 6, friendDay: 6, color: 'green' },
	{ name: "Kim Taehyung", friendYear: 1995, friendMonth: 12, friendDay: 30, color: 'orange' },
	{ name: "Kylie Jenner", friendYear: 1997, friendMonth: 8, friendDay: 10, color: 'pink' },
	{ name: "Lana Del Rey", friendYear: 1985, friendMonth: 6, friendDay: 21, color: 'blue' },
	{ name: "Logan Paul", friendYear: 1995, friendMonth: 4, friendDay: 1, color: 'green' },
	{ name: "Madison Beer", friendYear: 1999, friendMonth: 3, friendDay: 5, color: 'orange' },
	{ name: "Marilyn Monroe", friendYear: 1926, friendMonth: 6, friendDay: 1, color: 'pink' },
	{ name: "Michael B. Jordan", friendYear: 1987, friendMonth: 2, friendDay: 9, color: 'blue' },
	{ name: "Michelle Obama", friendYear: 1964, friendMonth: 1, friendDay: 17, color: 'green' },
	{ name: "Nicki Minaj", friendYear: 1982, friendMonth: 12, friendDay: 8, color: 'orange' },
	{ name: "Nina Dobrev", friendYear: 1989, friendMonth: 1, friendDay: 9, color: 'pink' },
	{ name: "Post Malone", friendYear: 1995, friendMonth: 6, friendDay: 4, color: 'blue' },
	{ name: "Rihanna", friendYear: 1988, friendMonth: 2, friendDay: 20, color: 'green' },
	{ name: "Robert Downey Jr", friendYear: 1965, friendMonth: 4, friendDay: 4, color: 'orange' },
	{ name: "Robert Pattinson", friendYear: 1986, friendMonth: 5, friendDay: 13, color: 'pink' },
	{ name: "Shay Mitchell", friendYear: 1987, friendMonth: 4, friendDay: 10, color: 'blue' },
	{ name: "Timothée Chalamet", friendYear: 1995, friendMonth: 12, friendDay: 27, color: 'green' },
	{ name: "Tom Felton", friendYear: 1987, friendMonth: 9, friendDay: 22, color: 'orange' },
	{ name: "Tom Holland", friendYear: 1996, friendMonth: 6, friendDay: 1, color: 'pink' },
	{ name: "Travis Scott", friendYear: 1991, friendMonth: 4, friendDay: 30, color: 'blue' },
	{ name: "Will Smith", friendYear: 1968, friendMonth: 9, friendDay: 25, color: 'green' },
	{ name: "Zayn Malik", friendYear: 1993, friendMonth: 1, friendDay: 12, color: 'orange' },
	{ name: "Zendaya", friendYear: 1996, friendMonth: 9, friendDay: 1, color: 'pink' }
];

export default celebrities;