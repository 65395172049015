import React, { Component } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { routeTypeDate, routeTypeCelebrities } from './index';
import SharedHomepage from './sharedHomepage.jsx';

export default class App extends Component {
	render() {
		const { type } = this.props;
		const isAstroForBae = type === routeTypeDate;
		return (
			<React.Fragment>
				{isAstroForBae ?
					(
						<HelmetProvider><Helmet>
							<title>Moonzo | I Get great dates based on your zodiac sign</title>
							<meta content="Moonzo | I Get great dates based on your zodiac sign" property="og:title" />
							<meta content="Moonzo | I Get great dates based on your zodiac sign" property="twitter:title" />
							<meta content="Meet Moonzo - the dating app that puts a new spin on dating, making friends, and building easy-going connections." name="description" />
							<meta content="Meet Moonzo - the dating app that puts a new spin on dating, making friends, and building easy-going connections." name="og:description" />
							<meta content="Meet Moonzo - the dating app that puts a new spin on dating, making friends, and building easy-going connections." name="twitter:description" />
							<meta property="og:image" content="https://www.moonzo.com/sharingFriends.png" />
							<meta name="apple-itunes-app" content="app-id=1538555981, app-argument='https://apps.apple.com/fr/app/moonzo-meet-new-friends/id1538555981?l=en'" />
							<link href="https://www.moonzo.com/moonzo_favicon.jpeg" rel="shortcut icon" type="image/x-icon" />
							<link href="https://www.moonzo.com/moonzo_favicon.jpeg" rel="apple-touch-icon" />
						</Helmet></HelmetProvider>) :
					(
						<HelmetProvider><Helmet>
							<title>Moonzo &bull; Astrology Matching | Meet New Friends</title>
							<meta content="Moonzo &bull; Astrology Matching | Meet New Friends" property="og:title" />
							<meta content="Moonzo &bull; Astrology Matching | Meet New Friends" property="twitter:title" />
							<meta content="Meet Moonzo - the astrology app that puts a new spin on dating, making friends, and building meaningful connections." name="description" />
							<meta content="Meet Moonzo - the astrology app that puts a new spin on dating, making friends, and building meaningful connections." property="og:description" />
							<meta content="Meet Moonzo - the astrology app that puts a new spin on dating, making friends, and building meaningful connections." property="twitter:description" />
							<meta property="og:image" content="https://www.moonzo.com/sharingFriends.png" />
							<meta name="apple-itunes-app" content="app-id=1538555981, app-argument='https://apps.apple.com/fr/app/moonzo-meet-new-friends/id1538555981?l=en'" />
							<link href="https://www.moonzo.com/moonzo_favicon.jpeg" rel="shortcut icon" type="image/x-icon" />
							<link href="https://www.moonzo.com/moonzo_favicon.jpeg" rel="apple-touch-icon" />
						</Helmet></HelmetProvider>)
				}
				<SharedHomepage type={routeTypeCelebrities} isLandingPage={true} />
			</React.Fragment>
		);
	}
}
