import React from 'react';
import LOADER from './images/LOADER.gif';
import celebrities from './celebrities';
import LazyLoad from 'react-lazy-load';
import LOGO from './images/LOGO.png';

class CelebritiesShowMore extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedCelebrity: null
		}
		this.isPhone = window.innerWidth < 768 ? true : false;
	}

	hilightCelebrity = (idx) => {
		const gallery = document.getElementsByClassName('celebrity-img');
		let selected = celebrities[idx];

		if (gallery) {
			if (selected === this.state.selectedCelebrity) {
				[...gallery].forEach(element => {
					element.classList.remove('celebrity-img-selected');
					this.setState({ selectedCelebrity: null });
				});
			} else {
				[...gallery].forEach(element => {
					element.classList.remove('celebrity-img-selected');
				});
				const selectedImage = document.getElementById(`carousel-item-${idx}`);
				selectedImage && selectedImage.classList.add('celebrity-img-selected');
				this.setState({ selectedCelebrity: selected });
			}
		}
	}

	selectCelebrity = () => {
		this.props.selectCelebrity(this.state.selectedCelebrity);
	}

	render() {
		return (
			<div className='moonzoShowMoreCelebs'>
				<div className='header'>
					<i className="fa fa-chevron-left text-white cursor-pointer" style={{ fontSize: '30px' }} onClick={this.props.closeShowMore} />
					<img className="moonzo-logo-resp" width="150" src={LOGO} alt="Moonzo" />
					<i className="fa fa-close text-white cursor-pointer" style={{ fontSize: '30px' }} onClick={this.props.closeShowMore} />
				</div>
				<h3 className="text-center wicked">Pick a celebrity</h3>
				<div className="row mt-4">
					{celebrities && celebrities.map((celebrity, index) => {
						return (
							<div className="col-lg-3 col-md-4 col-sm-6 col-6 mb-3 col-padding" key={`carousel-wrapper-${index}`}>
								<div key={`carousel-item-${index}`} style={{ background: 'transparent' }}>
									<div className="celebrity-bg-wrapper celebrity-padding position-relative" onClick={() => this.hilightCelebrity(index)}>
										<div className={`celebrity-bg ${(celebrity === this.state.selectedCelebrity) && 'celebrity-bg-selected'}`}>
											<LazyLoad debounce={false} offsetVertical={400}>
												<img id={`carousel-item-${index}`} src={`/celebrities/${this.isPhone ? 'mobile/' : ''}${celebrity.name}.png`} alt={'MEME ' + index} className={`celebrity-img img-fluid d-block celebrity-img-${celebrity?.color} ${this.state.selectedCelebrity && this.state.selectedCelebrity !== celebrities[index] && 'greyscale'}`} />
											</LazyLoad>
										</div>
									</div>
									<div className="celebrity-padding">
										<span className="leitura-b celebrity-name-padding d-block">{celebrity.name} {(celebrity === this.state.selectedCelebrity) && <i className="fa fa-check" style={{ color: '#FFCC6B' }} />}</span>
										{(this.state.selectedCelebrity === celebrity) && <button onClick={this.selectCelebrity} type="button" className="btn moonzo-btn-primary moonzo-btn text-center w-100" style={{ minWidth: 'auto' }}>{(this.props.loading && <img src={LOADER} height="30" alt="loader" />) || 'Let’s see!'}</button>}
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}

export default CelebritiesShowMore;