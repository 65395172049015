import React from 'react';
import LOGO from './images/LOGO.png';
import Footer from './Footer';

class EarlyModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            gender: '',
			email: '',
            city: '',
            hereapiError: null
        };
    }

    closeModal = () => {
        this.props.onClose();
    }

    handleSubmit = (event) => {
        event.preventDefault();
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="moonzo-modal-body h-100 h-100-early-responsive pb-5">
                    <div className="container h-100 h-100-early-responsive">
                        <div className="row w-100 mx-auto" style={{top: 0}}>
                            <div className="col px-0">
                                <div className="d-flex pt-3">
                                    <div className="vertical-align">
                                        <img className="moonzo-logo-resp" width="150" src={LOGO} alt="Moonzo"/>
                                    </div>
                                    <div className="ml-auto vertical-align">
										<i className="fa fa-times cursor-pointer" style={{fontSize: '35px'}} onClick={this.closeModal}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form className="w-100 h-100" onSubmit={this.handleSubmit} id="kol_bounce_form_317776" name="kol_bounce_form" autoComplete="off">
                            <div className="row mt-4 w-100 vertical-align h-100">
                                <div className="col">
                                    <h3 className="mb-3">Hey! We are still in private beta but are working hard to open things up soon! <br/> Sign up for early access below &#128071;</h3>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-4">
                                            <label htmlFor="inputName">Your first name</label>
                                            <input id="kol.317776.name" name="name" value={this.state.name} onChange={this.handleInputChange} type="text" className="form-control moonzo-input" aria-describedby="nameHelp" placeholder="Enter your first name"/>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-4">
                                            <label>Your gender</label>
                                            <select id="kol.317776.gender" name="gender" className="form-control moonzo-input" value={this.state.gender} onChange={this.handleInputChange}>
                                                <option value="" disabled>Choose your gender (Mandatory)</option>
                                                <option value="Woman">Woman</option>
                                                <option value="Man">Man</option>
                                                <option value="Non-binary">Non-binary</option>
                                                <option value="A different gender identity">A different gender identity</option>
                                                <option value="Rather not say">Rather not say</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-4">
                                            <label>Your city</label>
                                            <select id="kol.317776.city" name="city" className="form-control moonzo-input" value={this.state.city} onChange={this.handleInputChange}>
                                                <option value="" disabled>Choose your city (Mandatory)</option>
                                                <option value="New York">New York</option>
                                                <option value="Los Angeles">Los Angeles</option>
                                                <option value="Other in the US">Other in the US</option>
                                                <option value="Other outside the US">Other outside the US</option>
                                            </select>
                                        </div>
										<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-4">
                                            <div className="form-group">
                                                <label htmlFor="inputEmail" className="required">Your email address</label>
                                                <input required id="kol.317776.email" name="email" value={this.state.email} onChange={this.handleInputChange} type="email" className="form-control moonzo-input" aria-describedby="emailHelp" placeholder="moonzo@website.com"/>
                                            </div>      
                                        </div>
                                    </div>
                                    <div className="col pr-0 early-button-wrapper">
                                        <button id="kol_bounce_form_button_317776" type="submit" style={{minWidth: 250}} className="btn moonzo-btn-primary shadow-sm d-block moonzo-btn px-5 mr-0 ml-auto">
											Let me in !
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {this.state.hereapiError &&
                                <div className="row mt-3">
                                    <div className="col text-center">
                                        <span className="d-block my-1 color-red">There is an error with the location api, please try again later</span>
                                    </div>
                                </div>
                            }
                        </form>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

export default EarlyModal;