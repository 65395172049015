import React from 'react';
import TINDER from './images/TINDER2.png';
import STATISTICS from './images/STATISTICS.png';
import ATINDER from './images/astroforbae/TINDER2.png';
import ASTATISTICS from './images/astroforbae/STATISTICS.png';
import APPLELOGO from './images/Apple.png';
import CHAT from './images/CHAT.png';
import LazyLoad from 'react-lazyload';
import PEOPLES2 from './images/PEOPLES.png';
import PEOPLESMOBILE from './images/PEOPLESMOBILE.png';
import APEOPLES2 from './images/astroforbae/PEOPLES.png';
import APEOPLESMOBILE from './images/astroforbae/PEOPLESMOBILE.png';
import LOGO from './images/LOGO.png';
import TiktokIcon from './images/tiktok_white.svg';
import Instacon from './images/insta_white.svg';
import planetImage from './images/planetHomepage.svg';

class CompatibilityWrapper extends React.Component {
	constructor() {
		super();
		this.ref = React.createRef();
	}

	componentDidMount() {
		this.supportWebP();
	}

	executeScroll = () => window.scrollTo({ top: this.ref.current.offsetTop, behavior: 'smooth' });

	supportWebP = async () => {
		if (await window?.supportsWebP) {
			if (document.getElementById('section-3')) {
				document.getElementById('section-3').classList.add('s3-bg-mobile-webp');
				document.getElementById('section-3').classList.remove('s3-bg-mobile-jpg');
			}
		} else {
			if (document.getElementById('section-3')) {
				document.getElementById('section-3').classList.add('s3-bg-mobile-jpg');
				document.getElementById('section-3').classList.remove('s3-bg-mobile-webp');
			}
		}
	}

	openModal = (type) => {
		this.props.onOpen(type);
	}

	render() {
		const { isLandingPage, isAstroForBae } = this.props;
		return (
			<div className='multiSectionWrapper'>
				<section className="home-bg h-100 responsive-100 position-relative s1-bg-webp" id="section-1">
					<div className="container responsive-100 h-100 navbar-container">
						<div className="row navbar-row">
							<div className="col">
							{(isLandingPage || isAstroForBae) && <div className="d-flex pt-2">
									<div className="vertical-align">
										<a href="/">
											<img className="moonzo-logo-resp" width="150" src={LOGO} alt="Moonzo" />
										</a>
									</div>
									<div className="ml-auto vertical-align">
									<a href="https://www.instagram.com/moonzo.app/" target="_blank" rel="noopener noreferrer">
										<img width="50" src={Instacon} alt="Instagram icon" />
									</a>
									<a href="https://vm.tiktok.com/ZMejEY78h/" target="_blank" rel="noopener noreferrer" className='fs-s3'>
										<img width="50" src={TiktokIcon} alt="Tiktok icon" />
									</a>
									</div>
								</div>}
							</div>
						</div>
						<div className="row h-100">
							<div className="col-lg-6 col-md-6 vertical-align vertical-left header-wrapper zi-2">
								<div>
									<h1 className="d-block text-white display-4 mb-3 wicked animate__animated animate__fadeInLeft s1-sm-title-resp" style={window && window.innerWidth < 768 ? { lineHeight: 'unset' } : {}}>
										{(this.props.isAstroForBae) ?
											(<>Get great dates <br /> based on your <br /> zodiac <span role="img" aria-label="emoticon" style={{whiteSpace: 'nowrap',fontSize: 'inherit'}}>sign &#128171;</span></>) :
											<>{this.props.isLandingPage ? (<>Make friends <br /> based on your <br /> zodiac <span role="img" aria-label="emoticon" style={{whiteSpace: 'nowrap',fontSize: 'inherit'}}>signs &#128171;</span></>) : (<>Meet new <br /> friends based <br /> on <span role="img" aria-label="emoticon" style={{whiteSpace: 'nowrap',fontSize: 'inherit'}}>astrology &#128171;</span></>)}</>}
									</h1>
									{window && window.innerWidth < 768 && (<img width='342' src={this.props.isAstroForBae ? APEOPLESMOBILE : PEOPLESMOBILE} alt="PEOPLES" className="img-contain zi-1 w-100 sm-show mb-2" />)}
									<span className="d-block text-white mb-4 avenir-s">
										{(this.props.isAstroForBae) ?
											(<>Meet Moonzo - the dating app that puts a <br className="sm-hidden" /> new spin on making friends and <br className="sm-hidden" /> building easy-going connections.</>) :
											(<>Meet Moonzo - the astrology app that puts a <br className="sm-hidden" /> new spin on making friends and <br className="sm-hidden" /> building meaningful connections.</>)
										}
									</span>
									<a href={'https://moonzo.app.link/dlappstore'} className="btn-app-apple" target="_blank" rel="noopener noreferrer">
										<img src={APPLELOGO} alt="apple" width='41' className="mr-2 btn-apple-img" />
										<div>
											<span className="btn-app-subtitle">Download on the</span>
											<h2 className="btn-app-title">App Store</h2>
										</div>
									</a>
									<div className="text-center pt-3 pt-3"><i onClick={this.executeScroll} className="scroll-down cursor-pointer fa fa-chevron-down text-white text-center" height="10" style={{ zIndex: 10 }} /></div>
								</div>
							</div>
							{window && window.innerWidth > 768 && (<div className="col-lg-6 col-md-6 peoples-img sm-hidden">
								<div className="vertical-align h-100">
									<img width='607' src={this.props.isAstroForBae ? APEOPLES2 : PEOPLES2} alt="PEOPLES" className="img-contain zi-1" />
								</div>
							</div>)}
						</div>
					</div>
				</section>
				<section className="section-3" id="section-3" ref={this.ref}>
					{(isLandingPage || isAstroForBae) && <img className='planetHomePage' src={planetImage} alt=""/>}
					<div className="container h-100 position-relative">
						<div className="row">
							<div className="col-lg-4 col-md-4 moonzo-card-wrapper">
								<div className="card moonzo-card">
									<span className="card-img-top-wrapper vertical-align">
										<LazyLoad offset={300} once>
											<img src={isAstroForBae ? ATINDER : TINDER} width="280" alt="Moonzo perfect match" />
										</LazyLoad>
									</span>
									<div className="card-body pt-0">
										<h5 className="card-title text-center moonzo-card-title leitura">Meet the <br />Perfect Match</h5>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 moonzo-card-wrapper">
								<div className="card moonzo-card">
									<span className="card-img-top-wrapper vertical-align">
										<LazyLoad offset={300} once>
											<img src={isAstroForBae ? ASTATISTICS : STATISTICS} width="220" alt="Moonzo learn more" />
										</LazyLoad>
									</span>
									<div className="card-body pt-0">
										<h5 className="card-title text-center moonzo-card-title leitura">
											{(isAstroForBae) ?
												(<>Learn your <br /> strengths</>) :
												(<>Learn more <br /> about yourself</>)
											}
										</h5>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 moonzo-card-wrapper">
								<div className="card moonzo-card">
									<span className="card-img-top-wrapper vertical-align">
										<LazyLoad offset={300} once>
											<img src={CHAT} width="280" alt="Moonzo new friends" />
										</LazyLoad>
									</span>
									<div className="card-body pt-0">
										<h5 className="card-title text-center moonzo-card-title leitura">Chat with <br /> new friends</h5>
									</div>
								</div>
							</div>
						</div>
						<div className='text-center'>
							<a href={'https://moonzo.app.link/dlAppstorebutton2'} className="btn-app-apple" target="_blank" rel="noopener noreferrer">
								<img src={APPLELOGO} alt="apple" width='41' className="mr-2 btn-apple-img" />
								<div>
									<span className="btn-app-subtitle">Download on the</span>
									<h2 className="btn-app-title">App Store</h2>
								</div>
							</a>
						</div>
					</div>
				</section>
			</div>
		)
	}
}

export default CompatibilityWrapper;
