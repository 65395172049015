import React, { Component } from "react";
import TiktokIcon from './images/tiktok_dark.svg';
import Instacon from './images/insta_dark.svg';

export default class Footer extends Component {
  render() {
    return (
      <footer className="footer-container">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-5 text-left vertical-align vertical-left moonzo-footer-col">
              <span className="footer-copyright">Copyright © 2021 Tjbkg Consulting OU</span>
            </div>
            <div className="col-lg-2 col-md-2 vertical-align moonzo-footer-col">
              <a href="https://www.instagram.com/moonzo.app/" target="_blank" rel="noopener noreferrer">
                <img width="50" src={Instacon} alt="Instagram icon" />
              </a>
              <a href="https://vm.tiktok.com/ZMejEY78h/" target="_blank" rel="noopener noreferrer" className='fs-s3'>
                <img width="50" src={TiktokIcon} alt="Tiktok icon" style={{borderRadius: 9}} />
              </a>
            </div>
            <div className="col-lg-5 col-md-5 text-center moonzo-footer-col" style={{ justifyContent: 'right' }}>
              <div className="w-100 text-right">
                <a href="mailTo:team@moonzo.com" className="moonzo-a footer-moonzo-a footer-moonzo-left avenir-s">Contact us</a>
                <a href="https://www.notion.so/Privacy-b13301a47a8146ef88f07efa6775c6c5" rel="noopener noreferrer" target="_blank" className="moonzo-a footer-moonzo-a footer-moonzo-left avenir-s">Privacy</a>
                <a href="https://www.notion.so/Terms-of-service-72789501ae3c42e092526156be853c97" rel="noopener noreferrer" target="_blank" className="moonzo-a footer-moonzo-a avenir-s">Terms of Use</a>
                <a href="https://www.notion.so/Community-Guidelines-ead0e36072ec4f559363c39eb8af2bb2" rel="noopener noreferrer" target="_blank" className="moonzo-a footer-moonzo-a footer-moonzo-right avenir-s">Guidelines</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}