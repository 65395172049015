import React, { Component } from "react";

export default class AutoCompleteText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      text: "",
      locationId: ""
    };
  }

  onTextChanged = e => {
    const value = e?.target?.value;
    this.setState({ locationId: "", text: value });

    if (value?.length > 0) {
      this.setState({ text: value });
      fetch(
        `https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?query=${value}&apiKey=${this.props.appcode}`
      )
        .then(response => response.json())
        .then(data => {
          var op = data?.suggestions?.map(o => ({
            label: o.label,
            locationId: o.locationId
          }));

          this.setState({ suggestions: op });
        })
        .catch(err => {
          this.setState({ suggestions: [] });
        });
    } else {
      this.setState({ suggestions: [] });
    }
  };

  renderSuggestions = () => {
    const { suggestions } = this.state;

    if (suggestions?.length === 0) {
      return null;
    } else {
      return (
        <ul>
          {suggestions?.map(item => {
            return (
              <li
                key={item.locationId}
                locationid={item.locationId}
                onClick={() => this.suggestionSelected(item)}
              >
                {item.label}
              </li>
            );
          })}
        </ul>
      );
    }
  };

  suggestionSelected = async (item) => {
    this.setState({
      text: item.label,
      locationId: item.locationId,
      suggestions: []
    });
    const info = item?.locationId && await this.getTimezone(item.locationId);
    // LocalTime = aujourd'hui, prendre les dates de l'user + le gmt timezoneoffset et refaire un isDst

    const timezone = info?.TimeZoneOffset.substring(4);
    const timezoneHour = timezone.charAt(0) + timezone.charAt(1);
    const timezoneMinute = timezone?.charAt(3) + timezone?.charAt(4);
    let timezoneInSecond;
    const prefixe = info?.TimeZoneOffset?.charAt(3).toString() === '-' ? true : false;
    if (prefixe) {
      timezoneInSecond = '-' + (timezoneHour*3600) + (timezoneMinute*60);
    } else {
      timezoneInSecond = (timezoneHour*3600) + (timezoneMinute*60);
    }

    // TimezoneOffset, submit => isDst avec les dates
    const selectedTimezone = {tz: info?.TimeZoneOffset, lt: info?.LocalTime, tzs: timezoneInSecond};
    this.props.onSuggestionSelected(selectedTimezone);
  };

  getTimezone = async (cityId) => {
    const apiUrl = `https://geocoder.ls.hereapi.com/6.2/geocode.json?locationattributes=adminInfo,timeZone&apiKey=${this.props.appcode}&locationid=${cityId}`;
    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      const info = data?.Response?.View[0]?.Result[0]?.Location?.AdminInfo;
      if (info) {
        return info;
      }
      return;
    } catch(e) {
      // put an error message
      if (e) {
        this.props.handleAutoCompleteError(e);
      }
    }
  }

  render() {
    const { text } = this.state;
    const hasSuggestion = this.state.suggestions?.length > 0;
    return (
      <div className="AutoCompleteText position-relative">
        {
          (
            this.props.autoCompleteId && this.props.autoCompleteName &&
            <input
              id={this.props.autoCompleteId}
              name={this.props.autoCompleteName}
              autoComplete={'' + Math.random()}
              className={`form-control moonzo-input ${hasSuggestion ? 'has-suggestion' : 'no-suggestion'}`}
              type="text"
              value={text}
              onChange={this.onTextChanged}
              placeholder={this.props.placeholder}
            />
          ) || (
            <input
              autoComplete={'' + Math.random()}
              className={`form-control moonzo-input ${hasSuggestion ? 'has-suggestion' : 'no-suggestion'}`}
              type="text"
              value={text}
              onChange={this.onTextChanged}
              placeholder={this.props.placeholder}
            />
          )
        }
        {this.renderSuggestions()}
      </div>
    );
  }
}
