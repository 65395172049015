import React from 'react';
import {hydrate} from 'react-dom';
import './App.scss';
import {
	BrowserRouter as Router,
	Switch,
	Route
} from "react-router-dom";
import App from './App';
import AppCelebrity from './AppCelebrity';
import AppFriend from './AppFriend';
import NotFound from './NotFound';
import * as serviceWorker from './serviceWorker';

export const routeTypeDate = 'dates';
export const routeTypeCelebrities = 'celebrities';
export const routeTypeFriends = 'friends';

hydrate(
	<React.StrictMode>
		<Router>
			<Switch>
				<Route exact path="/dates">
					<App type={routeTypeDate} />
				</Route>
				<Route exact path="/celebrity-soulmate-compatibility">
					<AppCelebrity type={routeTypeCelebrities} />
				</Route>
				<Route exact path="/friendship-compatibility">
					<AppFriend type={routeTypeFriends} />
				</Route>
				<Route exact path="/">
					<App />
				</Route>
				<Route component={NotFound} />
			</Switch>
		</Router>
	</React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
