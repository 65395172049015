import React, { Component } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { routeTypeCelebrities } from './index';
import SharedHomepage from './sharedHomepage.jsx';

export default class App extends Component {
	render() {
		return (
			<React.Fragment>
				<HelmetProvider><Helmet>
                    <title>Calculate your Zodiac Compatibility With Celebrities for Free</title>
                    <meta content="Calculate your Zodiac Compatibility With Celebrities for Free" property="og:title" />
                    <meta content="Calculate your Zodiac Compatibility With Celebrities for Free" property="twitter:title" />
                    <meta content="Moonzo is a fun and easy way to find out if your celebrity crush is your soulmate." name="description" />
                    <meta content="Moonzo is a fun and easy way to find out if your celebrity crush is your soulmate." name="og:description" />
                    <meta content="Moonzo is a fun and easy way to find out if your celebrity crush is your soulmate." name="twitter:description" />
                    <meta property="og:image" content="https://www.moonzo.com/sharingCelebs.png" />
                    <link href="https://www.moonzo.com/moonzo_favicon.jpeg" rel="shortcut icon" type="image/x-icon" />
                    <link href="https://www.moonzo.com/moonzo_favicon.jpeg" rel="apple-touch-icon" />
                </Helmet></HelmetProvider>
				<SharedHomepage type={routeTypeCelebrities} />
			</React.Fragment>
		);
	}
}
